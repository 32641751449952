import '@/css/app.scss';
import { loadApp, loadModule } from '@spon/core';
import 'lazysizes';
import Header from '@/js/behaviours/Header';
import SetVh from '@/js/behaviours/SetVh';
import CookieConsent from '@/js/behaviours/CookieConsent';
import '@/js/utils/logger';
import detectTab from '@/js/utils/detect-tab';
import activeLinks from '@/js/utils/activeLinks';
import localLinks from '@/js/utils/localLinks';
import webFontLoader from '@/js/utils/webfont-loader';

activeLinks(window.location.pathname);
localLinks();
webFontLoader();

// App main
const main = async () => {
	// load from data-behaviours
	const app = loadApp(name => import(`./behaviours/${name}`), document.body);

	loadModule({
		module: Header,
		id: 'header',
		node: document.querySelector('header'),
		keepAlive: true,
	});

	loadModule({
		module: SetVh,
		id: 'set-vh',
		node: document.body,
		keepAlive: true,
	});

	loadModule({
		module: CookieConsent,
		id: 'cookie-consent',
		node: document.querySelector('#cookie-popup'),
		keepAlive: true,
	});

	detectTab();

	app.hydrate(document.body);

	return false;
};

// Execute async function
main().then(root => {
	// console.log('received', root);

	return null;
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
	module.hot.accept();
}
