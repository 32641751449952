/**
 * Remove slashes from the start and end of a string
 * @param {string} str
 * @return {string}
 */
function stripSlashes(str) {
	return str.replace(/(^\/+|\/+$)/g, '');
}

const header = document.getElementById('page-header');
const footer = document.getElementById('page-footer');

let $links = [
	...(header ? header.querySelectorAll('a') : []),
	...(footer ? footer.querySelectorAll('a') : []),
];

export default path => {
	$links.forEach(link => {
		link.classList.remove('is-active', 'is-current');
	});

	$links.forEach(link => {
		const lastPath = link.pathname.split('/');
		const [lastItem] = lastPath.slice(-1);

		// If home, just stop.

		if (lastItem.length < 1) return;

		if (stripSlashes(path).indexOf(lastItem) >= 0)
			link.classList.add('is-current');
	});
};
