import { withPlugins, domEvents } from '@spon/plugins';
import Cookies from 'js-cookie';
import { gsap } from 'gsap';

function CookieConsent({ node, plugins: { addEvents } }) {
	const hasCookie = Cookies.get('cookies-accepted');

	function closePopup() {
		if (!node) return;
		gsap.to(node, {
			duration: 0.2,
			ease: 'linear',
			y: '100%',
			delay: 0.3,
			onComplete: () => {
				node.remove();
			},
		});
	}

	function accept() {
		Cookies.set('cookies-accepted', true, { expires: 30 });
		Cookies.set('optOutGoogleTracking', false);
		closePopup();
	}

	// Remove 1 named cookie from lots of places
	function removeCookie(name) {
		Cookies.remove(name);
		Cookies.remove(name, { path: '/', domain: '.mudbank.uk' });
		Cookies.remove(name, { path: '/', domain: 'tambo.io' });
		Cookies.remove(name, { path: '/', domain: '.tambo.io' });
		Cookies.remove(name, { path: '/', domain: 'www.tambo.io' });
	}

	function deny() {
		// Remove the consent one
		Cookies.remove('cookies-accepted');

		// Then just get them all and remove in a loop.
		const allCookies = [...document.cookie.split('; ')];

		allCookies.forEach(cookie => {
			const name = cookie.split('=')[0];
			// Only remove if they dont contain craft!! - we need this for Forms to work
			if (!name.toLowerCase().includes('craft')) {
				removeCookie(name);
			}
		});

		// Remove scripts too
		const remove = [...document.querySelectorAll('.js-script__cookie')];
		remove.forEach(item => item.remove());

		// Set choise
		Cookies.set('cookies-accepted', 'no', { expires: 30 });

		// Specific GA ones
		Cookies.set('_ga', undefined);
		Cookies.set('_gid', undefined);
		Cookies.set('optOutGoogleTracking', true);

		closePopup();
	}

	// If we're accepted, delete and return
	if (hasCookie === 'yes') {
		if (node) node.remove();
		return;
	}

	// If we're in denial, double check remove all and return
	if (hasCookie === 'no') {
		deny();
		return;
	}

	function onClick(event) {
		event.preventDefault();

		const { cookie: answer } = event.target.dataset;
		if (answer === 'accept') accept();
		if (answer === 'deny') deny();
	}

	// Show it!
	function checkCookie() {
		if (!node) return;
		if (!hasCookie) {
			gsap.to(node, {
				duration: 0.4,
				ease: 'linear',
				y: 0,
				delay: 3,
			});
		}
	}
	checkCookie();

	addEvents({
		'click [data-cookie]': onClick,
	});
}

export default withPlugins(domEvents)(CookieConsent);
