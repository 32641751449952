import { withPlugins, eventBus, domEvents } from '@spon/plugins';

import Headroom from 'headroom.js';
import { setStyle } from '@/js/utils/set-style';
import toggle from '@/js/ui/toggle';

function Header({ node, name, plugins: { addEvents } }) {
	// Main Mobile Menu START
	let windowTop = 0;
	const nav = toggle({
		button: node.querySelector('.o-burger'),
		name,
		activeClass: 'is-active',
	});

	const lock = {
		capture() {
			windowTop = window.pageYOffset;
			setStyle(document.body, {
				position: 'fixed',
				height: '100%',
				top: `${windowTop * -1}px`,
				overflow: 'hidden',
				width: '100%',
			});
		},
		release() {
			setStyle(document.body, {
				position: '',
				height: '',
				top: '',
				overflow: '',
				width: '',
			});

			window.scrollTo = (0, windowTop);
		},
	};

	const navDropdowns = [...node.querySelectorAll('.o-nav--dropdown')];

	navDropdowns.forEach(parent => {
		const item = toggle({
			button: parent,
			name: parent.name,
			activeClass: 'is-active',
		});
		item.init();
	});

	nav.init();

	function clickWhenOpenHandle({ target }) {
		if (target.id === 'page-header' || target.closest('#page-header')) return; // Continue if clicking within #Header
		nav.close();
	}

	nav.on(`open:${name}`, ({ target }) => {
		node.classList.add('is-open');
		target.classList.add('is-open');

		eventBus.emit('menu:open');

		lock.capture();

		document.addEventListener('click', clickWhenOpenHandle);
	});

	nav.on(`close:${name}`, ({ target }) => {
		node.classList.remove('is-open');
		target.classList.remove('is-open');

		eventBus.emit('menu:close');

		lock.release();

		document.removeEventListener('click', clickWhenOpenHandle);
	});

	eventBus.on('page:exited', nav.close);
	// Main Mobile Menu END

	// Headroom
	const options = {
		// vertical offset in px before element is first unpinned
		offset: 0,
		// or you can specify tolerance individually for up/down scroll
		tolerance: {
			up: 5,
			down: 0,
		},
	};
	const headroom = new Headroom(node, options);
	headroom.init();
	eventBus.on('menu:open', () => headroom.freeze());
	eventBus.on('menu:close', () => headroom.unfreeze());

	function onKeyDown(event) {
		const { keyCode } = event;
		if (nav.isOpen && keyCode === 27) {
			nav.close();
		}
	}

	addEvents({
		keydown: onKeyDown,
	});
}

export default withPlugins(domEvents)(Header);
