import FontFaceObserver from 'fontfaceobserver';

export default () => {
	const $html = document.getElementsByTagName('html')[0];
	const heading = new FontFaceObserver('CarmenSans-ExtraBold');
	const headingAlt = new FontFaceObserver('CarmenSans-Bold');
	const light = new FontFaceObserver('CarmenSans-Light');

	Promise.all([heading.load(), headingAlt.load(), light.load()])
		.then(() => $html.classList.add('fonts-loaded'))
		.catch(() => $html.classList.add('fonts-failed'));
};
